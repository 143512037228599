import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-231b1272"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "component-group-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.content)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("label", null, _toDisplayString($setup.content), 1)
      ]))
    : _createCommentVNode("", true)
}