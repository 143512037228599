
import { ref } from "vue";

export default {
	name: "GroupLabel",
	components: {},
	setup(props, context) {
		let content = ref(context.attrs.content)
		return { content }
	}
}
