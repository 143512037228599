
import {onMounted, onUnmounted, ref} from "vue";
import {mapGetters, useStore} from "vuex";
import {useRoute} from "vue-router";
import router from "@/router";
import store from "@/store";
import FormPage from "@/components/FormPage.vue";
import FormHeader from "@/components/FormHeader.vue";
import axios from "axios";
import moment from "moment"

export default {
	name: "Form",
	components: {FormHeader, FormPage},
	setup() {
		const route = useRoute();
		const store = useStore();

		const formId = store.getters["form/getFormId"];
		let page = Number.parseInt(route.params.page + "");
		let content = ref({});
		let errors = ref([]);
		let person = ref(store.getters["form_data/getActivePersonRecord"]);
		let resource = ''
		let cities = []
		let schools = []
		let schools2 = []
		let cjisCodes = []
		let url = ''
		let schoolsUrl = ''
		
		if(page == 1){
			let resource = 'STATE/cities/COUNTY'
			url = 
				location.protocol +
				"//" +
				location.host +
				"/tables/ca/cities/cities.json"
				// resource
				// .replace("STATE", store.getters["session/getState"].toLowerCase())
				// .replace("COUNTY", store.getters["session/getCounty"].toLowerCase())
				// .replace(" ", "_") +
				// ".json";
			schoolsUrl = location.protocol + "//" + location.host + "/tables/ca/schools/schoolstemp.json"

			//let schoolsUrl2 = location.protocol + "//" + location.host + "/tables/ca/schools/schools_with_address.json"
			
			axios.get(url).then(response => {
				cities = response.data
			})	
			axios.get(schoolsUrl).then(response => {
				schools = response.data
			})	
			// axios.get(schoolsUrl2).then(response => {
			// 	schools2 = response.data
			// })	
		}
		if(page == 11 || page == 9){
			let resource = 'STATE/offenses'
			url = 
				location.protocol +
				"//" +
				location.host +
				"/tables/" +
				resource
				.replace("STATE", store.getters["session/getState"].toLowerCase())
				.replace("COUNTY", store.getters["session/getCounty"].toLowerCase())
				.replace(" ", "_") +
				".json";
			axios
				.get(url)
				.then((response) => {
					if (response.data) {
						cjisCodes = response.data
					}
				})
				.catch((error) => {
					console.log(error);
					console.log("error");
					return;
				});
		}
			
					

		function loadFormTemplate() {
			// load form if not loaded
			store.dispatch("form/loadSchema", route.params.form_id);

			// form failed to load
			if (!store.getters["form/isLoaded"]) {
				console.error("form template not loaded");
				return false;
			}

			// initialize form record from storage
			store.dispatch("form_data/loadFormRecordList");

			// display normal form page
			if (page != store.getters["form/maxPages"]) {
				content = store.getters["form/page"](page);
				if (page == 2) {
					let formRecord = store.getters["form_data/getFormRecord"]
					if (!formRecord.school && formRecord.school == null) {
						if (localStorage.getItem('goNext') == '1') {
							formRecord.people[person.value - 1].is_k12_student = ["No [N]"]
							goNext()
						} else {
							goBack()
						}

					}
				}
				if (page == 5) {
					let formRecord = store.getters["form_data/getFormRecord"]
					let activePerson = store.getters["form_data/getActivePerson"]
					if (activePerson.gender && activePerson.gender[0] && (!activePerson.gender[0].includes('Male') && !activePerson.gender[0].includes('Female'))) {

						if (localStorage.getItem('goNext') == '1') {
							activePerson.lgbt = ["Yes [Y]"]
							goNext()
						} else {
							goBack()
						}

					}
				}
				
				return true;
			}

			// reached form end, review
			router.push("/review");
			return true;
		}

		function IsNullOrEmpty(v) {
			return !(v !== undefined && v !== null && v.trim() !== "");
		}

		let warning_cjis_codes_empty = false

		function ValidateInputElementData() {
			let formTags = document.querySelectorAll(".component-part-tag [name]");
			let groups = [];
			errors.value = [];

			// add any location errors
			let locationError =
				store.getters["location/getGeolocationPositionErrorMessage"];

			if (locationError) {
				errors.value.push(locationError);
			}
			
			
			// search the page for named input elements
			Array.from(formTags).forEach((formTag: HTMLInputElement) => {
				let style = window.getComputedStyle(formTag);
				// let isVisible = !(style.display === 'none')
				let type = formTag.getAttribute("type");
				let name = formTag.getAttribute("name");
				let label = formTag.getAttribute("label");
				let value = formTag.value;
				let pattern = formTag.pattern;
				let min = formTag.min;
				let max = formTag.max;
				let checked = formTag.checked;
				let field = label;
				let fieldId = formTag.id;
				// value.includes('Search of Person was Conducted') ||

				if(page == 1){
					if(label == "Is a Residence or Non-Street" && checked == true){
						let isResidenceCount = 0
						Array.from(formTags).forEach((formTag: HTMLInputElement) => {
							let value = formTag.value;
							let label = formTag.getAttribute("label");
							let name = formTag.getAttribute("name");
							if(name == "landmark" && value != ""){
								isResidenceCount = isResidenceCount + 1
							}
							if(name == "highway_exit" && value != ""){
								isResidenceCount = isResidenceCount + 1
							}
							if(name == "intersection" && value != ""){
								isResidenceCount = isResidenceCount + 1
							}
						})
						if(isResidenceCount > 1){
							errors.value.push("Only one field is required.")
							return
						}
					}
				}
				if(page == 10){
					if(label == "Consent Given"){
						let consentChecked = 0
						Array.from(formTags).forEach((formTag: HTMLInputElement) => {
							let subFieldChecked = formTag.checked;
							let subFieldId = formTag.id;


							if(subFieldId == "actions_taken_search_person_consent_given" && subFieldChecked == true){
								consentChecked = 1
							}
							if(subFieldId == "actions_taken_search_property_consent_given" && subFieldChecked == true){
								consentChecked = 1
							}
							
						})
						if((checked == false && consentChecked == 1) || (checked == true && consentChecked == 0)){
							if(!errors.value.includes('“Search” must show Consent to Search was given for the “Search Basis” to be Consent Given')){
								errors.value.push('“Search” must show Consent to Search was given for the “Search Basis” to be Consent Given')
							}
							return
						}
						
						
					}
					
				}

				if(value.includes('Search of Property was Conducted')){
				let noError = false
				let searchConducted = false;

				Array.from(formTags).forEach((formTag: HTMLInputElement) => {
					let value = formTag.value;
					let fieldId = formTag.id;
					let checkedProperty = formTag.checked;
					let name = formTag.getAttribute("name");

					if(value.includes('Search of Property was Conducted') && checkedProperty == true){
					searchConducted = true
					}
					if(value.includes('Search of Person was Conducted') && checkedProperty == true){
					searchConducted = true
					}

					if(searchConducted && name == "search_basis"){
					if(checkedProperty){
						noError = true
					}

					}

				})
				if(searchConducted != false){
					if(!noError){
					errors.value.push("Search Basis is required.")
					return
					}
				}

				}

				//check if search encountered was conducted
				if(fieldId == 'actions_taken_none'){
					let activePerson = store.getters["form_data/getActivePerson"]
					if (activePerson.stop_reason[0].includes('Consensual Encounter and Search')){
						let noError = false;
						Array.from(formTags).forEach((formTag: HTMLInputElement) => {
							let checked = formTag.checked;
							let field = label;
							let fieldId = formTag.id;
							if(fieldId == 'actions_taken_search_person' || fieldId == 'actions_taken_search_property'){
								if(checked == true){
									noError = true
								}
								
							}
						})
						if(noError == false){
							errors.value.push("Search is required");
							return
						}	
					}

				}

				if(fieldId == 'city'){
					let cityExists = false
					for(let city of cities){
						if(city.toUpperCase() == value.toUpperCase()){
							cityExists = true
						}
					}
					if(cityExists == false){
						errors.value.push("You must select one of the DOJ approved cities from the displayed list.");
						return;
					}
				}

				if(fieldId == 'school' && value !=''){
					let schoolExists = false
					for(let school of schools){
						if(school.school.toUpperCase() == value.toUpperCase()){
							schoolExists = true
						}
					}
					if(schoolExists == false){
						errors.value.push("You must select one of the DOJ approved school from the displayed list.");
						return;
					}
				}

				if(name.includes('warning_cjis_codes') || name.includes('citation_cjis_codes') || name.includes('cite_and_release_cjis_codes') || 
					name.includes('arrest_cjis_codes') || name.includes('traffic_violation_code') || name.includes('reasonable_suspicion_code')){

					if(value && value != ''){
						
						let codeExists = false
						for(let code of cjisCodes){
							if(code.toUpperCase() == value.toUpperCase()){
								codeExists = true
							}
						}
						if(codeExists == false){
							let errorText = "You must select one of the DOJ approved CJIS Codes from the displayed list."
							if(name == 'traffic_violation_code'){
								errorText = "You must select one of the DOJ approved Traffic Violation Code from the displayed list."
							}
							if(name == 'reasonable_suspicion_code'){
								errorText = "You must select one of the DOJ approved Reasonable Suspicion Code from the displayed list."
							}
							if(errors.value.includes(errorText)){
								return
							}
							errors.value.push(errorText);
							return;
						}
					}

				}
				if(label == "Warning (Verbal or Written)" && checked == true){
					let valueExists = 0;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let name = formTag.getAttribute("name");

						if(name.includes('warning_cjis_codes') && value && value != ''){
							valueExists = 1
						}
					})
					if(valueExists == 0){
						errors.value.push("Warning CJIS Offense Code(s) is required");
						return
					}	
				}

				if(label == "Citation for Infraction (Use for Local Ordinances Only)" && checked == true){
					let valueExists = 0;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let name = formTag.getAttribute("name");

						if(name.includes('citation_cjis_codes') && value && value != ''){
							valueExists = 1
						}
					})
					if(valueExists == 0){
						errors.value.push("Citation CJIS Offense Code(s) is required");
						return
					}	
				}

				if(label == "In-field Cite and Release" && checked == true){
					let valueExists = 0;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let name = formTag.getAttribute("name");

						if(name.includes('cite_and_release_cjis_codes') && value && value != ''){
							valueExists = 1
						}
					})
					if(valueExists == 0){
						errors.value.push("In‐field cite and release CJIS Offense Code(s) is required");
						return
					}	
				}

				if(label == "Custodial Arrest Pursuant to Outstanding Warrant" && checked == true){
					let valueExists = 0;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let name = formTag.getAttribute("name");

						if(name.includes('arrest_cjis_codes') && value && value != ''){
							valueExists = 1
						}
					})
					if(valueExists == 0){
						errors.value.push("Custodial arrest CJIS Offense Code(s) is required");
						return
					}	
				}

				if(label == "Custodial Arrest Without Warrant" && checked == true){
					let valueExists = 0;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let name = formTag.getAttribute("name");

						if(name.includes('arrest_cjis_codes') && value && value != ''){
							valueExists = 1
						}
					})
					if(valueExists == 0){
						errors.value.push("Custodial arrest CJIS Offense Code(s) is required");
						return
					}	
				}

				// if(name.includes('warning_cjis_codes') && value == ''){
				// 	warning_cjis_codes_empty = true


				// }

				if (fieldId == "date") {
					if (moment(value) >  moment()) {
						errors.value.push("Stop Surveys are not permitted for future dates.")
						return
					}
				}

				if (fieldId == "time") {

					let date
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value
						let fieldId = formTag.id

						if (fieldId == "date" && value) {
							date = value
						}
					})


					var selectedDate = moment(date);
					var today = moment();

					
					if (selectedDate.format('YYYY-MM-DD') == today.format('YYYY-MM-DD')) {
						let time = value.split(':')


						if (+time[0] > moment().hours()) {
							errors.value.push("Stop Surveys are not permitted for future times.")
							return
						}
					}

				}

				if (fieldId == "residence_location_type" && checked == true) {
					let residenceAdded = false;
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let value = formTag.value;
						let fieldId = formTag.id;

						if (fieldId == "intersection" && value) {
							residenceAdded = true;
						}
						if (fieldId == "highway_exit" && value) {
							residenceAdded = true;
						}
						if (fieldId == "landmark" && value) {
							residenceAdded = true;
						}
					});
					if (residenceAdded == false) {
						errors.value.push(
							"Closest Intersection or Highway and Closest Exit or Road Marker, Landmark, Other is required"
						);
						return;
					}
				}

				if(name == "property_seizure_basis" && (label == 'Contraband' || label == 'Evidence')){
					
					if(checked == true){
						let contrabandCount = 0
						Array.from(formTags).forEach((formTag: HTMLInputElement) => {
							let name = formTag.getAttribute("name");
							let label = formTag.getAttribute("label");
							let checked = formTag.checked;

							if (name == "evidence_or_contraband" && label == "None" && checked == true) {
								if(!errors.value.includes('The value for Contraband or Evidence Discovered can not be none')){
									contrabandCount = contrabandCount + 1
									errors.value.push(
										"The value for Contraband or Evidence Discovered can not be none if the Property Seizure Basis is either Contraband or Evidence"
									);
								}
								return;
							}

							if (name == "evidence_or_contraband" && checked == true && label != "None") {
								contrabandCount = contrabandCount + 1
							}


						});
						if(contrabandCount == 0){
							if(!errors.value.includes('Contraband or Evidence Not found')){
								errors.value.push("Contraband or Evidence Not found");
							}
							return;
						}
					}
					
				}

				if(name == "stop_narrative" && value.length && (value.trim().length < 5 || value.trim().length > 250)){
					errors.value.push("Reason for Stop Explanation must be greater than 5 and less than 250 characters");
					return;
				}

				if(name == "evidence_or_contraband" && value == 'None [1]' && checked == true){
					let contrabandNoneError = 0
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let name = formTag.getAttribute("name");
						let label = formTag.getAttribute("label");
						let value = formTag.value;
						let checked = formTag.checked;
						if(name == "evidence_or_contraband" && value != 'None [1]' && checked == true ){
							contrabandNoneError = 1
						}

					})
					if(contrabandNoneError == 1){
						errors.value.push('In "Contraband or Evidence Discovered" you can not select "None" with any other options.');
						return
					}
				}

				if(label == 'Condition of Parole/Probation/PRCS/Mandatory Supervision' && checked == true){
					let searchBasisExplanationExists = 0
					let noConditionError = 0
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
							let name = formTag.getAttribute("name");
							let label = formTag.getAttribute("label");
							let checked = formTag.checked;
							let value = formTag.value;
							if(name == "search_basis"){
								if(label != 'Condition of Parole/Probation/PRCS/Mandatory Supervision' && checked == true){
									noConditionError = 1
								}
							}
							if(name == "search_basis_explanation"  && (value !="")){
								searchBasisExplanationExists = 1
							}
							
						
						})

					if(searchBasisExplanationExists == 0 && noConditionError == 1){
						errors.value.push('Search Basis Explanation is required 0');
						return
					}

					if(searchBasisExplanationExists == 1 && noConditionError == 0){
						errors.value.push('If the "Search Basis" is due to "Condition of Parole/Probation/PRCS/Mandatory Supervison", a "Search Basis Explanation" can not be filed.');
						return
					}
				}

				// handle radio or checkbox fields
				if (type == "radio" || type == "checkbox") {
					// was the radio or checkbox group already processed?
					if (groups.indexOf(name) !== -1) {
						// skip
						return;
					}

					// obtain all the values for the radio or checkbox
					let groupValues = document.querySelectorAll(
						'.component-part-tag [name="' + name + '"]:checked'
					);

					// customize the value for this iteration to use the remaining validation code
					if (groupValues.length > 0) {
						value = "true";
					} else {
						value = undefined;
					}

					// mark the element set as processed by adding the name to the groups array
					groups.push(name);
				}

				// is there a label for the error message?
				if (IsNullOrEmpty(label) || type == "radio" || type == "checkbox") {
					field = name
						.replaceAll("_", " ")
						.replace(/\b\w/g, (l) => l.toUpperCase());
				}

				// is the input element required?
				if (isVisible(formTag) && formTag.required && IsNullOrEmpty(value)) {
					errors.value.push(field + " is required");
					return;
				}

				// what type of input?
				switch (type) {
					case "number": {
						// numbers must not exceed min and max values
						if (!IsNullOrEmpty(min) && Number(value) < Number(min)) {
							errors.value.push(field + " is below the minimum value");
							return;
						} else if (!IsNullOrEmpty(max) && Number(value) > Number(max)) {
							errors.value.push(field + " is above the maximum value");
							return;
						}
					}
						break;
				}
				if(errors.value.includes('Search Basis Explanation is required')){
					Array.from(formTags).forEach((formTag: HTMLInputElement) => {
						let name = formTag.getAttribute("name");
						let label = formTag.getAttribute("label");
						let checked = formTag.checked;

						
						if(label == 'Condition of Parole/Probation/PRCS/Mandatory Supervision' && checked == true){
							
							errors.value.splice(errors.value.indexOf('Search Basis Explanation is required'), 1)
							return							
						}
					})
				}

				// if(name == 'search_basis_explanation' && (value !="")){
				// 	Array.from(formTags).forEach((formTag: HTMLInputElement) => {
				// 			let name = formTag.getAttribute("name");
				// 			let label = formTag.getAttribute("label");
				// 			let checked = formTag.checked;
				// 			let value = formTag.value;

				// 			if(label == 'Condition of Parole/Probation/PRCS/Mandatory Supervision' && checked == true){
				// 				errors.value.push('If the "Search Basis" is due to "Condition of Parole/Probation/PRCS/Mandatory Supervison", a "Search Basis Explanation" can not be filed.');
				// 				return
				// 			}
						
				// 		})
				// }
				
				// if condition parole checked ? then add logic
				
			});

			if(errors.value.includes('Evidence Or Contraband is required')){
				errors.value.push('CONTRABAND OR EVIDENCE DISCOVERED is a required field.')
				errors.value.splice(errors.value.indexOf('Evidence Or Contraband is required'), 1)
			}
			// return true if there aren't any errors, false otherwise
			return errors.value.length === 0;
		}

		function goHome() {
			router.push("/home");
		}

		function goBack() {
			localStorage.setItem('goNext', '0')
			if (page == 0) window.history.back();
			else {
				page = Math.max(page - 1, 0);
				router.push("/form/" + formId + "/page/" + page);
			}
		}

		function goNext() {
			localStorage.setItem('goNext', '1')
			// are there are errors in the input elements
			if (!ValidateInputElementData()) {
				// errors found, prevent continuing to the next page
				return;
			}
			syncInputElementData(store, false);
			page = Math.min(page + 1, store.getters["form/maxPages"]);
			router.push("/form/" + formId + "/page/" + page);
		}

		function onKeyDownEnter(event) {
			switch (event.keyCode) {
				case 13: {
					event.preventDefault();
					//syncInputElementData(store, true);
					goNext();
				}
					break;
			}
		}

		onMounted(() => {
			window.addEventListener("keydown", onKeyDownEnter);
		});

		onUnmounted(() => {
			window.removeEventListener("keydown", onKeyDownEnter);
		});

		loadFormTemplate();

		// store.dispatch("session/renew")

		return {content, store, errors, person, goHome, goBack, goNext};
	},
	computed: {
		...mapGetters("form", ["isLoaded", "schema"]),
		...mapGetters("form_data", ["isLoaded", "schema"]),
	},
	mounted() {
		syncInputElementData(store, true);
	},
};

function isVisible(e) {
	return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
}

function syncInputElementData(store, isLoading) {
	const route = useRoute();
	// find all form input tags that contain the "name" attribute
	let formTags = document.querySelectorAll(".component-part-tag [name]");
	//let page = Number.parseInt(route.params.page + "")
	// for each form input tag

	Array.from(formTags).forEach((formTag: HTMLInputElement) => {
		let style = window.getComputedStyle(formTag);
		// let isVisible = style.display != 'none'
		let type = formTag.getAttribute("type");
		let name = formTag.getAttribute("name");
		let objStore = formTag.getAttribute("store");
		let value = formTag.value;
		let checked = formTag.checked;
		let fieldId = formTag.id;
		let label = formTag.getAttribute("label");


		if (fieldId == 'block_number') {
			let seperateValue = value.split('')
			if (seperateValue.length > 2) {
				seperateValue[seperateValue.length - 1] = '0'
				seperateValue[seperateValue.length - 2] = '0'
				value = seperateValue.join('')
			} else if (seperateValue.length == 2) {
				seperateValue[1] = '0'
				value = seperateValue.join('')
			}
		}
		


		// load or save operation?
		if (isLoading) {
			// load operation
			let formDataItem = store.getters["form_data/getFormDataItem"]({
				store: objStore,
				key: name,
				default: null,
			});

			if (typeof formDataItem === "undefined") {
				return;
			}

			if (name === 'actions_taken' &&
				formDataItem.includes('Asked for Consent to Search Person ["17,N"]') &&
				formDataItem.includes('Asked for Consent to Search Person ["17,Y"]')) {
				formDataItem = formDataItem.filter(item => item !== 'Asked for Consent to Search Person ["17,N"]');
			}

			if (name === 'actions_taken' &&
				formDataItem.includes('Asked for Consent to Search Property ["19,N"]') &&
				formDataItem.includes('Asked for Consent to Search Property ["19,Y"]')) {
				formDataItem = formDataItem.filter(item => item !== 'Asked for Consent to Search Property ["19,N"]');
			}

			if (type == "radio" || type == "checkbox") {
				formTag.checked = false;

				// treat radio and checkbox elements as an array
				formDataItem.forEach((item: string) => {
					//if there is a consent to search in the data, then the consent asked checkbox needs to be marked
					if (
						item.includes("Asked for Consent to Search Person") &&
						formTag.id === "actions_taken_search_person_consent"
					) {
						formTag.checked = true;

						formTag.dispatchEvent(new Event("input"));
						formTag.dispatchEvent(new Event("change"));
						formTag.dispatchEvent(new Event("click"));
						return;
					}

					if (
						item.includes("Asked for Consent to Search Person") &&
						formTag.id === "actions_taken_search_person_consent_given"
					) {
						formTag.checked = item.includes("17,Y");

						formTag.dispatchEvent(new Event("input"));
						formTag.dispatchEvent(new Event("change"));
						formTag.dispatchEvent(new Event("click"));
						return;
					}

					if (
						item.includes("Asked for Consent to Search Property") &&
						formTag.id === "actions_taken_search_property_consent"
					) {
						formTag.checked = true;

						formTag.dispatchEvent(new Event("input"));
						formTag.dispatchEvent(new Event("change"));
						formTag.dispatchEvent(new Event("click"));
						return;
					}

					if (
						item.includes("Asked for Consent to Search Property") &&
						formTag.id === "actions_taken_search_property_consent_given"
					) {
						formTag.checked = item.includes("19,Y");

						formTag.dispatchEvent(new Event("input"));
						formTag.dispatchEvent(new Event("change"));
						formTag.dispatchEvent(new Event("click"));
						return;
					}

					if (value === item) {
						formTag.checked = true;

						formTag.dispatchEvent(new Event("input"));
						formTag.dispatchEvent(new Event("change"));
						formTag.dispatchEvent(new Event("click"));
					}
				});
			} else {
				// everything else is an object property
				formTag.value = formDataItem;

				formTag.dispatchEvent(new Event("input"));
				formTag.dispatchEvent(new Event("change"));
				formTag.dispatchEvent(new Event("click"));
			}
		} else {
			// save operation
			// array storage or non-array storage
			if (type == "radio" || type == "checkbox") {
				//special case for Y/N questions on was consent given
				if (
					formTag.id === "actions_taken_search_person_consent" ||
					formTag.id === "actions_taken_search_property_consent"
				) {
					return;
				}

				if (formTag.id === "actions_taken_search_person_consent_given") {
					//if the consent given tag isn't visible, then we need to make sure and remove it's values if present
					if (!isVisible(formTag)) {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,N"]',
							store: objStore,
							default: [],
						});
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,Y"]',
							store: objStore,
							default: [],
						});
						return;
					}
					if (checked) {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,N"]',
							store: objStore,
							default: [],
						});

						store.dispatch("form_data/addArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,Y"]',
							store: objStore,
							default: [],
						});
					} else {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,Y"]',
							store: objStore,
							default: [],
						});

						store.dispatch("form_data/addArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Person ["17,N"]',
							store: objStore,
							default: [],
						});
					}

					return;
				}

				if (formTag.id === "actions_taken_search_property_consent_given") {
					if (!isVisible(formTag)) {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,N"]',
							store: objStore,
							default: [],
						});
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,Y"]',
							store: objStore,
							default: [],
						});
						return;
					}

					if (checked) {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,N"]',
							store: objStore,
							default: [],
						});
						store.dispatch("form_data/addArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,Y"]',
							store: objStore,
							default: [],
						});
					} else {
						store.dispatch("form_data/removeArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,Y"]',
							store: objStore,
							default: [],
						});
						store.dispatch("form_data/addArrayItem", {
							key: name,
							value: 'Asked for Consent to Search Property ["19,N"]',
							store: objStore,
							default: [],
						});
					}

					return;
				}

				// treat radio and checkbox elements as an array
				if (isVisible(formTag) && checked && value) {
					store.dispatch("form_data/addArrayItem", {
						key: name,
						value: value,
						store: objStore,
						default: [],
					});
				} else {
					store.dispatch("form_data/removeArrayItem", {
						key: name,
						value: value,
						store: objStore,
						default: [],
					});
				}
			} else {
				// everything else is an object property
				if (isVisible(formTag) && value) {
					store.dispatch("form_data/setItemValue", {
						key: name,
						value: value,
						store: objStore,
						default: {},
					});
				} else {
					store.dispatch("form_data/clearItemValue", {
						key: name,
						value: value,
						store: objStore,
						default: {},
					});
				}
			}
		}
	});

}
