
import { onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref } from "vue";
import Input from "@/components/Input.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import axios from "axios";
import { useStore } from "vuex";
import useEmitter from "@/composables/UseEmitter"
import {useRoute} from "vue-router";

export default {
  name: "PartTag",
  components: { AutoComplete, Input },
  setup: function (props, context) {
    const store = useStore();
    const route = useRoute();
    const emitter = useEmitter()
    const content = context.attrs.content;
    const tag = ref(content.tag);
    const tag_id = ref(content.id);
    const inner_content = ref(content.content);
    const part_required = ref(content.required);
    const class_name = ref(content.class_name);
    let label = ref(content.label);
    let tag_value = ref(null);
    let name = ref(null);
    let obj_store = ref(null);
    let type = ref(null);
    let checked = ref(null);
    let placeholder = ref(null);
    let min = ref(null);
    let max = ref(null);
    let minlength = ref(null);
    let maxlength = ref(null);
    let required = ref(null);
    let pattern = ref(null);
    let onclick_func = ref(null);
    let oninput_func = ref(null);
    let onload_func = ref(null);
    let suggestions = ref(null);
    let subscription = null;
    let unsubscribe = null;
    let sub_component = ref(context.attrs.sub_component);
    let search = ref(null)




    




    function get_date() {
      let d = new Date();
      return (
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        d.getDate().toString().padStart(2, "0")
      );
    }

    function get_time() {
      let d = new Date();
      return (
        d.getHours().toString().padStart(2, "0") +
        ":" +
        d.getMinutes().toString().padStart(2, "0") +
        ":" +
        d.getSeconds().toString().padStart(2, "0")
      );
    }

    function update_location() {
      store.dispatch("location/requestLocation").then(() => {
         let updatedLocation =  JSON.parse(sessionStorage.getItem('updatedLocation'))

        emitter.emit("updateAddress", updatedLocation)

      });

    }

    function dynamic_execute(event) {
      switch (event.type) {
        case "click":
          eval(onclick_func);
          break;
        case "input":
          eval(oninput_func);
          break;
      }
    }

    function clear_page_inputs() {
      let formTags = document.querySelectorAll(".component-part-tag [name]");

      Array.from(formTags).forEach((formTag: HTMLInputElement) => {
        let type = formTag.getAttribute("type");

        switch (type) {
          case "radio":
          case "checkbox":
            formTag.checked = false;
            break;
          default:
            formTag.value = "";
            break;
        }
      });
    }

    function remove_from_array_if_stored(
      storeContainer,
      storeKey,
      storedValue
    ) {
      if (is_stored(storeContainer, storeKey, storedValue)) {
        store.dispatch("form_data/removeArrayItem", {
          key: name,
          value: storedValue,
          store: storeContainer,
          default: [],
        });
      }
    }

    function is_stored(storeContainer, storeKey, storedValue) {
      let obj = store.getters["form_data/getFormDataItem"]({
        store: storeContainer,
        key: storeKey,
        default: "",
      });

      switch (typeof obj) {
        case "object":
          if (obj.includes(storedValue)) {
            return true;
          }
          break;
        default:
          if (obj == storedValue) {
            return true;
          }
          break;
      }

      return false;
    }

    function if_stored_add_class(
      storeContainer,
      storeKey,
      storedValue,
      objectId,
      className
    ) {
      if (is_stored(storeContainer, storeKey, storedValue)) {
        add_class(objectId, className);
      }
    }

    function if_stored_remove_class(
      storeContainer,
      storeKey,
      storedValue,
      objectId,
      className
    ) {
      if (is_stored(storeContainer, storeKey, storedValue)) {
        remove_class(objectId, className);
      }
    }

    function if_stored_toggle_class(
      storeContainer,
      storeKey,
      storedValue,
      objectId,
      className
    ) {
      if (is_stored(storeContainer, storeKey, storedValue)) {
        toggle_class(objectId, className);
      }
    }

    function update_char_count(
      textAreaObjectId,
      messageObjectId,
      maxByteCount
    ) {
      const src = document.getElementById(
        textAreaObjectId
      ) as HTMLTextAreaElement;
      const dst = document.getElementById(messageObjectId);

      let count = src.value.length ? src.value.length : 0;
      let difference: number = maxByteCount - count;
      let output = difference ? difference : 0;

      dst.innerHTML = output + " characters remaining";
    }

    function clamp(val, min, max) {
      return val > max ? max : val < min ? min : val;
    }

    function is_checked(objectId): boolean {
      let selectedObject = document.getElementById(
        objectId
      ) as HTMLInputElement;

      if (selectedObject) {
        return selectedObject.checked;
      }

      return false;
    }

    function has_class(objectId, className): boolean {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        return selectedObject.classList.contains(className);
      }

      return false;
    }

    function show_element(objectId: string, andRelated: boolean) {
      let element: Element = document.getElementById(objectId);

      if (element) {
        element.classList.remove("is-hidden");

        if (andRelated) {
          let parentObject = element.parentElement;

          if (parentObject.tagName.toLowerCase() === "div") {
            for (let index = 0; index < parentObject.children.length; index++) {
              const element = parentObject.children[index];
              element.classList.remove("is-hidden");
            }
          }
        }
      } else {
        console.log("Element " + objectId + " not found");
      }
    }

    function hide_element(objectId: string, andRelated:boolean) {
      let element: Element = document.getElementById(objectId);

      if (element) {
        element.classList.add("is-hidden");

        if (andRelated) {
          let parentObject = element.parentElement;

          if (parentObject.tagName.toLowerCase() === "div") {
            for (let index = 0; index < parentObject.children.length; index++) {
              const element = parentObject.children[index];
              element.classList.add("is-hidden");
            }
          }
        }
      }
    }

    function toggle_class_in_div(objectId, className) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        let parentObject = selectedObject.parentElement;

        if (parentObject.tagName.toLowerCase() === "div") {
          for (let index = 0; index < parentObject.children.length; index++) {
            const element = parentObject.children[index];
            toggle_class_by_element(element, className, true);
          }
        }
      }
    }

    function indent_sub_component(objectId) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        let parentObject = selectedObject.parentElement;

        if (parentObject.tagName.toLowerCase() === "div") {
          parentObject.classList.add("sub-component-indent");
        }
      }
    }

    function toggle_class_recursive(objectId, className) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        toggle_class_by_element(selectedObject, className, true);
      }
    }

    function toggle_class_by_element_and_related(
      elementId: string,
      className: string
    ) {
      let element = document.getElementById(elementId);
      element.classList.toggle(className);

      let related = document.querySelectorAll("[for=" + element.id + "]");
      if (related) {
        for (let index = 0; index < related.length; index++) {
          const element = related[index];
          element.classList.toggle(className);
        }
      }
    }

    function toggle_class_by_element(
      element: Element,
      className: string,
      recurse: boolean
    ) {
      element.classList.toggle(className);

      if (recurse) {
        let childrenCount = element.childElementCount;
        if (childrenCount > 0) {
          for (let index = 0; index < childrenCount; index++) {
            toggle_class_by_element(element.children[index], className, true);
          }
        }
      }
    }

    function toggle_class(objectId, className) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        toggle_class_by_element(selectedObject, className, false);
      }
    }

    function add_class(objectId, className) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        selectedObject.classList.add(className);
      }
    }

    function remove_class(objectId, className) {
      let selectedObject = document.getElementById(objectId);

      if (selectedObject) {
        selectedObject.classList.remove(className);
      }
    }

    function checkIfConsensualSelected() {
      return "not-hidden";
    }

    // function showContraband() {
    //   let selectedObject = document.getElementById(objectId);

    //   if (selectedObject) {
    //     selectedObject.classList.remove(className);
    //   }
    // }

    function load_table_data(resource, target, parseJson = false) {
      let url = location.protocol + "//" + location.host
      let subUrl = location.protocol + "//" + location.host
        // resource
        //   .replace("STATE", store.getters["session/getState"].toLowerCase())
        //   .replace("COUNTY", store.getters["session/getCounty"].toLowerCase())
        //   .replace(" ", "_") +
        // ".json";
      if(resource.includes('cities')){
        url = url + "/tables/ca/cities/cities.json"
      }
      if(resource.includes('schools')){
        url = url + "/tables/ca/schools/schoolstemp.json"
      }
      if(resource.includes('offenses')){
        url = url + '/tables/' + resource
          .replace("STATE", store.getters["session/getState"].toLowerCase())
          .replace("COUNTY", store.getters["session/getCounty"].toLowerCase())
          .replace(" ", "_") +
        ".json";
      }

      axios
        .get(url)
        .then((response) => {
          if (response.data) {
            if (parseJson) {
              target.value = JSON.parse(response.data);
            } else {
              target.value = response.data;
            }
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          console.log("error");
          return;
        });

      // axios
      //   .get(subUrl)
      //   .then((response) => {
      //     console.log(response.data)
          
      //     if (response.data) {
      //       if (parseJson) {
      //         target.value = JSON.parse(response.data);
      //       } else {
      //         target.value = response.data;
      //       }
      //       console.log(target.value)
      //       return;
      //     }
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     console.log("error");
      //     return;
      //   });
    }

    onBeforeMount(() => {
      if (subscription != null) {
        unsubscribe = store.subscribe((mutation, state) => {
          tag_value.value = store.getters[subscription];
        });
      }
    });
    

    onMounted(() => {
      eval(onload_func);
       let tempName1 = name
      
       if(tempName1 == 'block_number' || tempName1 == 'street_name' || tempName1 == 'intersection'){

        emitter.on("setSchoolAddress", (params) => {
         
          if(Number.parseInt(route.params.page + "") == 1){
            let tempName = name
            if(tempName == 'block_number'){ 
              let isnum = /^\d+$/.test(params.street.split(' ')[0]);
              
              if(isnum == true) {
                search.value = params.street.split(' ')[0]
                tag_value.value = params.street.split(' ')[0]
              }
            }
            if(tempName == 'street_name'){ 
              let isnum = /^\d+$/.test(params.street.split(' ')[0]);

              if(isnum){ 
                let streetArray = params.street.split(' ')
                streetArray[0] = '';
                let newStreetVal = streetArray.join(' ')
                search.value = newStreetVal.trim()
                tag_value.value = newStreetVal.trim()
              } else{
                search.value = params.street
                tag_value.value = params.street
              }
            }
            //if(tempName == 'city') search.value = params.city
            
          }
          
        })

        emitter.on("updateAddress", (params) => {
          let tempName = name
          

          if(tempName == 'block_number' && params.house_number){ 
              let isnum = /^\d+$/.test(params.house_number.split(' ')[0]);

              if(isnum == true) {
                search.value = params.house_number.split(' ')[0]
                tag_value.value = params.house_number.split(' ')[0]
              }
          }

          if(tempName == 'street_name' && params.road){ 
            search.value = params.road
          }

          if(tempName == 'intersection' && params.road){ 
            search.value = params.road
          }
          
        })
        
       }
    });

    onBeforeUnmount(() => {
      if (subscription != null) {
        unsubscribe();
      }
    });

     onUnmounted(() => {
      emitter.off("setSchoolAddress")
      emitter.off("updateAddress")
    })

    if (part_required.value == "true") {
      class_name.value += " part-required";
    }

    if (typeof content.attributes != "undefined") {
      content.attributes.forEach((prop) => {
        switch (prop.key) {
          case "comment":
            break;
          case "name":
            name = prop.value;
            break;
          case "store":
            obj_store = prop.value;
            break;
          case "type":
            type = prop.value;
            break;
          case "checked":
            checked = prop.value;
            break;
          case "placeholder":
            placeholder = prop.value;
            break;
          case "min":
            min = prop.value;
            break;
          case "max":
            max = prop.value;
            break;
          case "minlength":
            minlength = prop.value;
            break;
          case "maxlength":
            maxlength = prop.value;
            break;
          case "required":
            required = prop.value;
            break;
          case "sub_component":
            sub_component.value = prop.value;
            break;
          case "pattern":
            pattern = prop.value;
            break;
          case "onclick":
            onclick_func = prop.value;
            break;
          case "oninput":
            oninput_func = prop.value;
            break;
          case "onload":
            onload_func = prop.value;
            break;
          case "value":
            tag_value = prop.value;
            break;
          case "date":
            if (tag_value.value == null) {
              tag_value.value = get_date();
            }
            break;
          case "time":
            if (tag_value.value == null) {
              tag_value.value = get_time();
            }
            break;
          case "subscribe":
            subscription = prop.value;
            tag_value = ref(store.getters[subscription]);
            break;
          case "suggestions":
            suggestions.value = JSON.parse(prop.value);
            break;
          case "suggestion-table":
            load_table_data(prop.value, suggestions);
            break;
          default:
            break;
        }
      });
    }

    return {
      store,
      tag,
      tag_id,
      tag_value,
      inner_content,
      label,
      name,
      obj_store,
      type,
      checked,
      class_name,
      placeholder,
      required,
      min,
      max,
      minlength,
      maxlength,
      sub_component,
      pattern,
      onload_func,
      onclick_func,
      oninput_func,
      suggestions,
      update_location,
      is_checked,
      clear_page_inputs,
      has_class,
      add_class,
      remove_class,
      toggle_class,
      toggle_class_recursive,
      update_char_count,
      clamp,
      dynamic_execute,
      search,
      is_stored,
      if_stored_add_class,
      show_element,
      hide_element,
      if_stored_remove_class,
      if_stored_toggle_class,
      toggle_class_in_div,
      remove_from_array_if_stored,
      indent_sub_component,
      toggle_class_by_element_and_related,
      subscription,
      unsubscribe,
      checkIfConsensualSelected,
      //showContraband
    };
  },
};
