import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f62cfc14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-form-page container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageSection = _resolveComponent("PageSection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.sections)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($setup.sections, (section, index) => {
          return (_openBlock(), _createBlock(_component_PageSection, {
            key: index,
            content: section
          }, null, 8, ["content"]))
        }), 128))
      : _createCommentVNode("", true)
  ]))
}