import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupLabel = _resolveComponent("GroupLabel")!
  const _component_PartTag = _resolveComponent("PartTag")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["component-section-group", $setup.class_name]),
    id: $setup.group_id
  }, [
    _createVNode(_component_GroupLabel, { content: $setup.label }, null, 8, ["content"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.parts, (part, index) => {
      return (_openBlock(), _createBlock(_component_PartTag, {
        key: index,
        content: part
      }, null, 8, ["content"]))
    }), 128))
  ], 10, _hoisted_1))
}