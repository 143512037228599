
import { ref } from "vue";
import PageSection from "@/components/PageSection.vue"

export default {
	name: "FormPage",
	components: { PageSection },
	setup(props, context) {
		let content = ref(context.attrs.content)
		let sections = ref()

		if (typeof(content.value) != "undefined") {
			sections = content.value.sections
		}
		return { sections }
	}
}
