
import {ref, onMounted, onUnmounted} from 'vue'
import useEmitter from "@/composables/UseEmitter"
import {useRoute} from "vue-router";

export default {
	name: 'AutoComplete',
	inheritAttrs: false,
	emits: ['search'],
	props: {
		suggestions: {
			type: Array,
			required: false,
			default: () => []
		},
		isAsync: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props, context) {
		const emitter = useEmitter()
		const route = useRoute();
		const autoCompleteRef = ref(null)
		const label = context.attrs.label
		const tag_id = context.attrs.id
		const store = context.attrs.store
		const name = context.attrs.name
		const placeholder = context.attrs.placeholder
		const required = context.attrs.required
		const min = context.attrs.min
		const max = context.attrs.max
		const action = context.attrs.action
		let isOpen = ref(false)
		let results = ref([])
		let search = ref("")
		let isLoading = ref(false)
		let arrowCounter = ref(0)
		let class_name = ref(context.attrs.class_name)
		let listType = ref('string')

		function onChange() {
			// are we loading data from an external source?
			if (props.isAsync) {
				isLoading.value = true
			} else {
				// was nothing loaded?
				if (!props.suggestions) {
					// console.log("onChange: no suggestions")
					return
				}

				filterResults()
				isOpen.value = true
			}
		}

		function filterResults() {
			if (!props.suggestions) {
				// console.log("filterResults: no suggestions")
				return
			}
			
			if(typeof props.suggestions[0] == 'string'){
				listType.value = 'string'
				results.value = props.suggestions.filter(suggestion => {
					return suggestion.toLowerCase().indexOf(search.value.toLowerCase()) > -1
				})
				
			} else{
				listType.value = 'object'
				results.value = props.suggestions.filter(suggestion => {
					return suggestion.school.toLowerCase().indexOf(search.value.toLowerCase()) > -1
				})
			}
			
		}

		function setResult(result) {
			search.value = result.school || result
			isOpen.value = false

			if(result.street)
				emitter.emit("setSchoolAddress", result)
		}

		function onArrowDown() {
			if (arrowCounter.value < results.value.length) {
				arrowCounter.value = arrowCounter.value + 1
			}
		}

		function onArrowUp() {
			if (arrowCounter.value > 0) {
				arrowCounter.value = arrowCounter.value - 1
			}
		}

		function onEnter() {
			search.value = results.value[arrowCounter.value]
			isOpen.value = false
			this.arrowCounter = -1
		}

		function handleClickOutside(evt) {
			if (!autoCompleteRef.value.contains(evt.target)) {
				isOpen.value = false
				arrowCounter.value = -1
			}
		}

		onMounted(() => {
			let tempName1 = name
      
			if(tempName1 == 'city'){
				emitter.on("setSchoolAddress", (params) => {

					if(Number.parseInt(route.params.page + "") == 1){
						let tempName = name
						if(tempName == 'city') search.value = params.city
						
					}
				
				})
				emitter.on("updateAddress", (params) => {
						let tempName = name
						if(tempName == 'city') search.value = params.city
				})
			}
		});



		onUnmounted(() => {
			emitter.off("setSchoolAddress")
			emitter.off("updateAddress")
		})

		return {
			autoCompleteRef,
			label,
			tag_id,
			store,
			name,
			placeholder,
			required,
			min,
			max,
			action,
			isOpen,
			results,
			search,
			isLoading,
			arrowCounter,
			class_name,
			onChange,
			setResult,
			onArrowDown,
			onArrowUp,
			onEnter,
			handleClickOutside
		}
	},
	watch: {
		suggestions: function(val, oldValue) {
			if (val && oldValue && val.length !== oldValue.length) {
				this.results = val;
				this.isLoading = false;
			}
		}
	}
}
