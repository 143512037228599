
import { ref } from "vue";
import GroupLabel from "@/components/GroupLabel.vue"
import PartTag from "@/components/PartTag.vue"

export default {
	name: "SectionGroup",
	components: { GroupLabel, PartTag },
	setup(props, context) {
		let content = ref(context.attrs.content)
		let parts = ref(content.value.parts)
		let label = ref(content.value.label) // optional
		let class_name = ref(content.value.class_name) // optional
		let group_id = ref(content.value.id) // optional

		return { parts, label, class_name, group_id }
	}
}
