import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-631356fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "component-part-tag" }
const _hoisted_2 = ["id", "name"]
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id", "name", "store", "placeholder", "required"]
const _hoisted_7 = {
  key: 7,
  class: "field"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id", "name", "store", "type", "pattern", "placeholder", "required"]
const _hoisted_10 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tag === 'autocomplete')
      ? (_openBlock(), _createBlock(_component_AutoComplete, {
          key: _ctx.tag_value,
          label: _ctx.label,
          id: _ctx.tag_id,
          name: _ctx.name,
          store: _ctx.obj_store,
          value: _ctx.tag_value,
          class_name: _ctx.class_name,
          placeholder: _ctx.placeholder,
          min: _ctx.min,
          max: _ctx.max,
          minlength: _ctx.minlength,
          maxlength: _ctx.maxlength,
          required: _ctx.required,
          pattern: _ctx.pattern,
          suggestions: _ctx.suggestions,
          onClick: _ctx.dynamic_execute
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.inner_content), 1)
          ]),
          _: 1
        }, 8, ["label", "id", "name", "store", "value", "class_name", "placeholder", "min", "max", "minlength", "maxlength", "required", "pattern", "suggestions", "onClick"]))
      : (_ctx.tag === 'input')
        ? (_openBlock(), _createBlock(_component_Input, {
            key: _ctx.tag_value,
            label: _ctx.label,
            id: _ctx.tag_id,
            name: _ctx.name,
            store: _ctx.obj_store,
            value: _ctx.tag_value,
            type: _ctx.type,
            class_name: _ctx.class_name,
            placeholder: _ctx.placeholder,
            min: _ctx.min,
            max: _ctx.max,
            minlength: _ctx.minlength,
            maxlength: _ctx.maxlength,
            required: _ctx.required,
            pattern: _ctx.pattern,
            checked: _ctx.checked,
            sub_component: _ctx.sub_component,
            onClick: _ctx.dynamic_execute,
            onInput: _ctx.dynamic_execute
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.inner_content), 1)
            ]),
            _: 1
          }, 8, ["label", "id", "name", "store", "value", "type", "class_name", "placeholder", "min", "max", "minlength", "maxlength", "required", "pattern", "checked", "sub_component", "onClick", "onInput"]))
        : (_ctx.tag === 'a')
          ? (_openBlock(), _createElementBlock("a", {
              key: 2,
              id: _ctx.tag_id,
              name: _ctx.name,
              class: _normalizeClass(_ctx.class_name),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
            }, _toDisplayString(_ctx.inner_content), 11, _hoisted_2))
          : (_ctx.tag === 'button')
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                id: _ctx.tag_id,
                name: _ctx.name,
                class: _normalizeClass(_ctx.class_name),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
              }, _toDisplayString(_ctx.inner_content), 11, _hoisted_3))
            : (_ctx.tag === 'label')
              ? (_openBlock(), _createElementBlock("label", {
                  key: 4,
                  id: _ctx.tag_id,
                  class: _normalizeClass(_ctx.class_name),
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                }, _toDisplayString(_ctx.inner_content), 11, _hoisted_4))
              : (_ctx.tag === 'span')
                ? (_openBlock(), _createElementBlock("span", {
                    key: 5,
                    id: _ctx.tag_id,
                    class: _normalizeClass(_ctx.class_name),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                  }, _toDisplayString(_ctx.inner_content), 11, _hoisted_5))
                : (_ctx.tag === 'textarea')
                  ? (_openBlock(), _createElementBlock("textarea", {
                      key: _ctx.tag_value,
                      id: _ctx.tag_id,
                      name: _ctx.name,
                      store: _ctx.obj_store,
                      class: _normalizeClass(_ctx.class_name),
                      placeholder: _ctx.placeholder,
                      required: _ctx.required,
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args))),
                      onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                    }, null, 42, _hoisted_6))
                  : (_ctx.tag === 'input2')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("label", {
                          class: _normalizeClass(["newInputTitle", _ctx.class_name]),
                          id: _ctx.tag_id,
                          onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                        }, _toDisplayString(_ctx.label), 11, _hoisted_8),
                        _withDirectives((_openBlock(), _createElementBlock("input", {
                          class: _normalizeClass(["newInputField", _ctx.class_name]),
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.search) = $event)),
                          key: _ctx.tag_value,
                          id: _ctx.tag_id,
                          name: _ctx.name,
                          store: _ctx.obj_store,
                          type: _ctx.type,
                          pattern: _ctx.pattern,
                          placeholder: _ctx.placeholder,
                          required: _ctx.required,
                          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args))),
                          onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                        }, null, 42, _hoisted_9)), [
                          [_vModelDynamic, _ctx.search]
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 8,
                        ref: _ctx.tag,
                        id: _ctx.tag_id,
                        class: _normalizeClass(_ctx.class_name),
                        onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.dynamic_execute && _ctx.dynamic_execute(...args)))
                      }, _toDisplayString(_ctx.inner_content), 11, _hoisted_10))
  ]))
}