
import { ref } from "vue";
import SectionGroup from "@/components/SectionGroup.vue"

export default {
	name: "PageSection",
	components: { SectionGroup },
	setup(props, context) {
		let content = ref(context.attrs.content)
		let label = ref(content.value.label)
		let required = ref(content.value.required)
		let help = ref(content.value.help)
		let groups = ref(content.value.groups)
		return { label, required, help, groups }
	}
}
